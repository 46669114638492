<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogGroup"
        max-width="1400px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Thêm coupon group</span
              >
              <span class="headline" v-else>Sửa coupon group</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogGroup = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Name:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="name_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Prefix:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="prefix_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Date:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4 d-flex mb-4">
                      <date-picker
                        style="width: 40%"
                        class=""
                        v-model="from_date_input"
                        value-type="format"
                        format="YYYY-MM-DD"
                      />
                      <v-icon class="mt-1 ml-4 mr-4"
                        >mdi-arrow-right-bold-outline</v-icon
                      >
                      <date-picker
                        style="width: 40%"
                        class=""
                        v-model="to_date_input"
                        value-type="format"
                        format="YYYY-MM-DD"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Quantity:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="quantity_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Có thể kết hợp ct giảm khác:</label
                      ></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-radio-group
                        v-model="can_combined_input"
                        row
                        class="mt-0"
                      >
                        <v-radio label="Yes" value="yes"></v-radio>
                        <v-radio label="No" value="no"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Type discount:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-radio-group v-model="type_input" row class="mt-0">
                        <v-radio label="Direct discount" :value="1"></v-radio>
                        <v-radio
                          label="% Discount of product"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Discount Amount:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4 d-flex">
                      <v-text-field
                        style="max-width: 50%"
                        type="number"
                        v-model="amount_input"
                        dense
                        filled
                      ></v-text-field>
                      <p
                        v-if="type_input === 1"
                        class="mt-2 text-h6 font-weight-bold"
                      >
                        VNĐ
                      </p>
                      <p
                        v-if="type_input === 2"
                        class="mt-2 text-h6 font-weight-bold"
                      >
                        %
                      </p>
                      <p
                        v-if="
                          type_input === 1 &&
                          amount_input !== null &&
                          amount_input !== ''
                        "
                        class="mt-2 ml-2 text-subtitle-1"
                      >
                        ({{ amount_input | formatNumber }})
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nguồn:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="source_input"
                        :items="source_coupon"
                        color="blue-grey lighten-2"
                        filled
                        item-text="label"
                        item-value="value"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Trạng thái:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-radio-group v-model="status_input" row class="mt-0">
                        <v-radio
                          label="Active"
                          :value="status.ACTIVE"
                        ></v-radio>
                        <v-radio
                          label="Inactive"
                          :value="status.INACTIVE"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Product Category:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="product_category_ids"
                        :items="product_categories"
                        item-text="name.vi"
                        item-value="id"
                        color="blue-grey lighten-2"
                        filled
                        chips
                        multiple
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="
                              removeOptionTag(data.item, 'product_category')
                            "
                          >
                            {{ data.item.name.vi }}
                          </v-chip>
                        </template>
                        <template v-slot:item="data">
                          <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content
                              v-text="data.item"
                            ></v-list-item-content>
                          </template>
                          <template v-else>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="data.item.name.vi"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Số lương chương trình tối thiểu để áp dụng:</label
                      ></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-text-field
                        type="number"
                        label=""
                        v-model="minimum_programme_quantity_input"
                        filled
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!--                  <v-row>-->
                  <!--                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Áp dụng tất cả programme:</label></v-col>-->
                  <!--                    <v-col cols="8" class="p-0 m-0 ml-4">-->
                  <!--                      <v-switch class="mt-0 pt-0 ml-2" v-model="is_apply_all_programme" :label="is_apply_all_programme === true ? 'Yes' : 'No'"></v-switch>-->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                  <!--                  <v-row v-if="is_apply_all_programme === false">-->
                  <!--                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Chương trình:</label></v-col>-->
                  <!--                    <v-col cols="8" class="p-0 m-0 ml-4">-->
                  <!--                      <v-autocomplete-->
                  <!--                          v-model="programme_ids"-->
                  <!--                          :disabled="isUpdating"-->
                  <!--                          :items="programme_tags"-->
                  <!--                          filled-->
                  <!--                          chips-->
                  <!--                          color="blue-grey lighten-2"-->
                  <!--                          label=""-->
                  <!--                          item-text="name.vi"-->
                  <!--                          item-value="id"-->
                  <!--                          multiple-->
                  <!--                      >-->
                  <!--                        <template v-slot:selection="data">-->
                  <!--                          <v-chip-->
                  <!--                              v-bind="data.attrs"-->
                  <!--                              :input-value="data.selected"-->
                  <!--                              close-->
                  <!--                              @click="data.select"-->
                  <!--                              @click:close="removeOptionTag(data.item, 'programme')"-->
                  <!--                          >-->
                  <!--                            {{ data.item.name.vi }}-->
                  <!--                          </v-chip>-->
                  <!--                        </template>-->
                  <!--                        <template v-slot:item="data">-->
                  <!--                          <template v-if="typeof data.item !== 'object'">-->
                  <!--                            <v-list-item-content v-text="data.item"></v-list-item-content>-->
                  <!--                          </template>-->
                  <!--                          <template v-else>-->
                  <!--                            <v-list-item-content>-->
                  <!--                              <v-list-item-title v-html="data.item.name.vi"></v-list-item-title>-->
                  <!--                            </v-list-item-content>-->
                  <!--                          </template>-->
                  <!--                        </template>-->
                  <!--                      </v-autocomplete>-->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Chương trình tặng:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="gift_programme_ids"
                        :items="programme_tags"
                        filled
                        chips
                        color="blue-grey lighten-2"
                        label=""
                        item-text="name.vi"
                        item-value="id"
                        multiple
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="
                              removeOptionTag(data.item, 'gift_programme')
                            "
                          >
                            {{ data.item.name.vi }}
                          </v-chip>
                        </template>
                        <template v-slot:item="data">
                          <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content
                              v-text="data.item"
                            ></v-list-item-content>
                          </template>
                          <template v-else>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="data.item.name.vi"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditGroup(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditGroup(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogGroup = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "GroupForm",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    source_coupon: {
      type: Array,
      default: () => [],
    },
    programme_tags: {
      type: Array,
      default: () => [],
    },
    product_categories: {
      type: Array,
      default: () => [],
    },
    coupon_group: {},
  },
  data() {
    return {
      is_call_api: false,
      status: {
        ACTIVE: 1,
        INACTIVE: 0,
      },
      name_input: "",
      prefix_input: "",
      from_date_input: null,
      to_date_input: null,
      quantity_input: null,
      can_combined_input: "no",
      type: {
        DIRECT: 1,
        PERCENTAGE: 2,
      },
      type_input: 1,
      amount_input: null,
      source_input: null,
      gift_programme_ids: [],
      product_category_ids: [],
      is_apply_all_programme: false,
      programme_ids: [],
      isUpdating: false,
      status_input: 1,
      coupon_group_id: null,
      minimum_programme_quantity_input: null,
    };
  },
  computed: {
    dialogGroup: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogGroup(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.name_input = "";
        this.prefix_input = "";
        this.from_date_input = null;
        this.to_date_input = null;
        this.quantity_input = null;
        this.can_combined_input = "no";
        this.type_input = this.type.DIRECT;
        this.amount_input = null;
        this.source_input = null;
        this.status_input = this.status.ACTIVE;
        this.product_category_ids = [];
        this.is_apply_all_programme = false;
        this.programme_ids = [];
        this.gift_programme_ids = [];
        this.minimum_programme_quantity_input = null;
      } else {
        let group = JSON.parse(JSON.stringify(this.coupon_group));
        this.coupon_group_id = group.id;
        this.name_input = group.name;
        this.prefix_input = group.prefix;
        this.from_date_input = group.from_date;
        this.to_date_input = group.to_date;
        this.quantity_input = group.quantity;
        this.can_combined_input = group.can_combined === 1 ? "yes" : "no";
        this.type_input = group.discount_type;
        this.amount_input = group.discount_amount;
        this.source_input = group.source;
        this.status_input = group.status;
        this.product_category_ids = group.productCategories.map((e) => {
          return e.id;
        });
        this.is_apply_all_programme = group.is_apply_all_programme === 1;
        this.programme_ids = group.programmes.map((e) => {
          return e.id;
        });
        this.gift_programme_ids = group.gift_programmes;
        this.minimum_programme_quantity_input =
          group.minimum_programme_quantity;
      }
    },
    validateData() {
      let flat = true;
      if (this.name_input === "" || this.name_input == null) {
        this.$toasted.error("Chưa nhập tên !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.prefix_input === "" || this.prefix_input == null) {
        this.$toasted.error("Chưa nhập prefix !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.from_date_input == null) {
        this.$toasted.error("Hãy nhập thời gian bắt đầu coupon !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.to_date_input == null) {
        this.$toasted.error("Hãy nhập thời gian kết thúc coupon !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.from_date_input != null && this.to_date_input != null) {
        if (
          Date.parse(this.from_date_input) >= Date.parse(this.to_date_input)
        ) {
          this.$toasted.error(
            "Thời gian kết thúc phải lớn hơn thời gian bắt đầu !!",
            { theme: "toasted-primary", position: "top-right", duration: 4000 }
          );
          flat = false;
        }
        if (this.type_form === "add") {
          if (
            Date.parse(this.from_date_input) <
            Date.parse(
              this.$options.filters.convertTimestampToDate(Date.now() / 1000)
            )
          ) {
            this.$toasted.error(
              "Thời gian bắt đầu không thể là thời gian trong quá khứ !!",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
            flat = false;
          }
        }
      }
      if (this.quantity_input === "" || this.quantity_input == null) {
        this.$toasted.error("Hãy nhập quantity !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.source_input == null) {
        this.$toasted.error("Hãy chọn nguồn coupon !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.amount_input == null || this.amount_input <= 0) {
        this.$toasted.error("Giá tiền giảm phải lớn hơn 0 !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.minimum_programme_quantity_input <= 0) {
        this.$toasted.error(
          "Số lương áp dụng chương trình tối thiểu phải lớn hơn 0 !!",
          { theme: "toasted-primary", position: "top-right", duration: 4000 }
        );
        flat = false;
      }

      return flat;
    },
    btnAddOrEditGroup(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let data = {
        name: this.name_input,
        prefix: this.prefix_input,
        from_date: this.from_date_input,
        to_date: this.to_date_input,
        quantity: this.quantity_input,
        can_combined: this.can_combined_input === "yes" ? 1 : 0,
        discount_type: this.type_input,
        discount_amount: this.amount_input,
        source: this.source_input,
        status: this.status_input,
        is_apply_all_programme: this.is_apply_all_programme ? 1 : 0,
        programmes: this.programme_ids,
        productCategories: this.product_category_ids,
        gift_programmes: this.gift_programme_ids,
        minimum_programme_quantity: this.minimum_programme_quantity_input,
      };
      this.is_call_api = true;
      if (type === 1) {
        ApiService.post("prep-app/coupon-group", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogGroup = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        ApiService.put("prep-app/coupon-group/" + this.coupon_group_id, data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogGroup = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    removeOptionTag(item, type) {
      let index;
      if (type === "programme") {
        index = this.programme_ids.indexOf(item.id);
        if (index >= 0) this.programme_ids.splice(index, 1);
      }
      if (type === "gift_programme") {
        index = this.gift_programme_ids.indexOf(item.id);
        if (index >= 0) this.gift_programme_ids.splice(index, 1);
      }
      if (type === "product_category") {
        index = this.product_category_ids.indexOf(item.id);
        if (index >= 0) this.product_category_ids.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped></style>
